[data-color-mode="dark"][data-dark-theme="dark_high_contrast"],
[data-color-mode="dark"][data-dark-theme="dark_high_contrast"] ::backdrop,
[data-color-mode="auto"][data-light-theme="dark_high_contrast"],
[data-color-mode="auto"][data-light-theme="dark_high_contrast"] ::backdrop {
  --button-danger-iconColor-rest: #ffb1af;
  --button-primary-bgColor-active: #109135;
  --button-primary-bgColor-disabled: #048f2f;
  --button-primary-bgColor-hover: #08792b;
  --color-ansi-cyan: #39c5cf;
  --color-ansi-cyan-bright: #56d4dd;
  --control-checked-bgColor-active: #3c79d0;
  --control-checked-bgColor-hover: #2b64c1;
  --control-danger-bgColor-active: #c51120;
  --fgColor-accent: #74b9ff;
  --fgColor-done: #d3abff;
  --fgColor-sponsors: #ff90c8;
  --fgColor-success: #2bd853;
  --reactionButton-selected-bgColor-hover: #5dadff5c;
  --avatar-shadow: 0px 0px 0px 2px #0d1117;
  --avatarStack-fade-bgColor-default: #3d444d;
  --avatarStack-fade-bgColor-muted: #2a313c;
  --bgColor-accent-emphasis: #194fb1;
  --bgColor-accent-muted: #5cacff1a;
  --bgColor-attention-emphasis: #7b4900;
  --bgColor-attention-muted: #edaa2726;
  --bgColor-danger-emphasis: #ad0116;
  --bgColor-danger-muted: #ff80801a;
  --bgColor-disabled: #262c36;
  --bgColor-done-emphasis: #6921d7;
  --bgColor-done-muted: #bf8fff26;
  --bgColor-emphasis: #3d444d;
  --bgColor-muted: #151b23;
  --bgColor-neutral-emphasis: #3d444d;
  --bgColor-neutral-muted: #212830;
  --bgColor-severe-emphasis: #8f3c00;
  --bgColor-severe-muted: #f48b251a;
  --bgColor-sponsors-emphasis: #9c1d6a;
  --bgColor-sponsors-muted: #f87cbd1a;
  --bgColor-success-emphasis: #006222;
  --bgColor-success-muted: #0ac74026;
  --bgColor-transparent: #00000000;
  --borderColor-accent-emphasis: #409eff;
  --borderColor-accent-muted: #5cacff;
  --borderColor-attention-emphasis: #e09b13;
  --borderColor-attention-muted: #edaa27;
  --borderColor-danger-emphasis: #ff6a69;
  --borderColor-danger-muted: #ff8080;
  --borderColor-default: #b7bdc8;
  --borderColor-disabled: #656c761a;
  --borderColor-done-emphasis: #b87fff;
  --borderColor-done-muted: #bf8fff;
  --borderColor-severe-emphasis: #e7811d;
  --borderColor-severe-muted: #f48b25;
  --borderColor-sponsors-emphasis: #ef6eb1;
  --borderColor-sponsors-muted: #f87cbd;
  --borderColor-success-emphasis: #09b43a;
  --borderColor-success-muted: #0ac740;
  --borderColor-translucent: #2f3742;
  --borderColor-transparent: #00000000;
  --button-danger-borderColor-active: #ffb1af;
  --button-danger-borderColor-hover: #ffb1af;
  --button-danger-fgColor-rest: #ffb1af;
  --button-danger-shadow-selected: 0px 0px 0px 0px #000000;
  --button-default-shadow-resting: 0px 0px 0px 0px #000000;
  --button-inactive-fgColor: #b7bdc8;
  --button-invisible-bgColor-active: #212830;
  --button-invisible-bgColor-disabled: #00000000;
  --button-invisible-bgColor-hover: #151b23;
  --button-invisible-borderColor-disabled: #00000000;
  --button-invisible-iconColor-hover: #d1d7e0;
  --button-invisible-iconColor-rest: #d1d7e0;
  --button-outline-bgColor-active: #2672f3;
  --button-outline-bgColor-rest: #f0f6fc;
  --button-outline-fgColor-disabled: #74b9ff80;
  --button-outline-fgColor-hover: #71b7ff;
  --button-outline-fgColor-rest: #5cacff;
  --button-outline-shadow-selected: 0px 0px 0px 0px #000000;
  --button-primary-borderColor-active: #4ae168;
  --button-primary-borderColor-disabled: #4ae16866;
  --button-primary-borderColor-hover: #4ae168;
  --button-primary-borderColor-rest: #4ae168;
  --button-primary-shadow-selected: 0px 0px 0px 0px #000000;
  --button-star-iconColor: #f7c843;
  --buttonCounter-default-bgColor-rest: #2f3742;
  --buttonCounter-outline-bgColor-hover: #194fb133;
  --buttonCounter-outline-bgColor-rest: #194fb133;
  --buttonCounter-outline-fgColor-disabled: #74b9ff80;
  --buttonCounter-outline-fgColor-hover: #71b7ff;
  --buttonCounter-outline-fgColor-rest: #5cacff;
  --codeMirror-selection-bgColor: #5cacff66;
  --codeMirror-syntax-fgColor-comment: #656c76;
  --codeMirror-syntax-fgColor-constant: #91cbff;
  --codeMirror-syntax-fgColor-entity: #dbb7ff;
  --codeMirror-syntax-fgColor-keyword: #ff9492;
  --codeMirror-syntax-fgColor-storage: #ff9492;
  --codeMirror-syntax-fgColor-string: #addcff;
  --codeMirror-syntax-fgColor-support: #91cbff;
  --codeMirror-syntax-fgColor-variable: #ffb757;
  --color-ansi-black: #2f3742;
  --color-ansi-black-bright: #656c76;
  --color-ansi-blue: #71b7ff;
  --color-ansi-blue-bright: #91cbff;
  --color-ansi-gray: #656c76;
  --color-ansi-green: #28d751;
  --color-ansi-green-bright: #4ae168;
  --color-ansi-magenta: #cb9eff;
  --color-ansi-magenta-bright: #dbb7ff;
  --color-ansi-red: #ff9492;
  --color-ansi-red-bright: #ffb1af;
  --color-ansi-white: #f0f6fc;
  --color-ansi-yellow: #f0b72f;
  --color-ansi-yellow-bright: #f7c843;
  --color-prettylights-syntax-brackethighlighter-angle: #9198a1;
  --color-prettylights-syntax-brackethighlighter-unmatched: #ff8080;
  --color-prettylights-syntax-carriage-return-bg: #ff4445;
  --color-prettylights-syntax-carriage-return-text: #f0f6fc;
  --color-prettylights-syntax-comment: #9198a1;
  --color-prettylights-syntax-constant: #91cbff;
  --color-prettylights-syntax-constant-other-reference-link: #addcff;
  --color-prettylights-syntax-entity: #dbb7ff;
  --color-prettylights-syntax-entity-tag: #72f088;
  --color-prettylights-syntax-invalid-illegal-bg: #e82a2f;
  --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
  --color-prettylights-syntax-keyword: #ff9492;
  --color-prettylights-syntax-markup-bold: #f0f6fc;
  --color-prettylights-syntax-markup-changed-bg: #a74c00;
  --color-prettylights-syntax-markup-changed-text: #ffe1b4;
  --color-prettylights-syntax-markup-deleted-bg: #cc1421;
  --color-prettylights-syntax-markup-deleted-text: #ffdedb;
  --color-prettylights-syntax-markup-heading: #409eff;
  --color-prettylights-syntax-markup-ignored-bg: #318bf8;
  --color-prettylights-syntax-markup-ignored-text: #f0f6fc;
  --color-prettylights-syntax-markup-inserted-bg: #007728;
  --color-prettylights-syntax-markup-inserted-text: #acf7b6;
  --color-prettylights-syntax-markup-italic: #f0f6fc;
  --color-prettylights-syntax-markup-list: #fbd669;
  --color-prettylights-syntax-meta-diff-range: #dbb7ff;
  --color-prettylights-syntax-storage-modifier-import: #f0f6fc;
  --color-prettylights-syntax-string: #addcff;
  --color-prettylights-syntax-string-regexp: #72f088;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #3d444d;
  --color-prettylights-syntax-variable: #ffb757;
  --contribution-default-bgColor-0: #151b23;
  --contribution-default-bgColor-1: #007728;
  --contribution-default-bgColor-2: #02a232;
  --contribution-default-bgColor-3: #0ac740;
  --contribution-default-bgColor-4: #4ae168;
  --contribution-default-borderColor-0: #ffffff0d;
  --contribution-default-borderColor-1: #ffffff0d;
  --contribution-default-borderColor-2: #ffffff0d;
  --contribution-default-borderColor-3: #ffffff0d;
  --contribution-default-borderColor-4: #ffffff0d;
  --contribution-halloween-bgColor-1: #fac68f;
  --contribution-halloween-bgColor-2: #c46212;
  --contribution-halloween-bgColor-3: #984b10;
  --contribution-halloween-bgColor-4: #e3d04f;
  --contribution-winter-bgColor-1: #1e60d5;
  --contribution-winter-bgColor-2: #318bf8;
  --contribution-winter-bgColor-3: #71b7ff;
  --contribution-winter-bgColor-4: #caeaff;
  --control-bgColor-active: #2f3742;
  --control-bgColor-hover: #2a313c;
  --control-bgColor-rest: #262c36;
  --control-borderColor-selected: #f0f6fc;
  --control-checked-borderColor-active: #91cbff;
  --control-checked-borderColor-hover: #91cbff;
  --control-checked-borderColor-rest: #91cbff;
  --control-danger-fgColor-hover: #ff9492;
  --control-fgColor-placeholder: #b7bdc8;
  --control-transparent-bgColor-active: #3d444d;
  --control-transparent-bgColor-hover: #2f3742;
  --control-transparent-bgColor-rest: #00000000;
  --control-transparent-bgColor-selected: #2f3742;
  --control-transparent-borderColor-rest: #00000000;
  --controlTrack-bgColor-active: #3d444d;
  --controlTrack-bgColor-hover: #2f3742;
  --controlTrack-bgColor-rest: #2a313c;
  --data-auburn-color-emphasis: #a86f6b;
  --data-auburn-color-muted: #271817;
  --data-blue-color-emphasis: #0576ff;
  --data-blue-color-muted: #001a47;
  --data-brown-color-emphasis: #94774c;
  --data-brown-color-muted: #241c14;
  --data-coral-color-emphasis: #e1430e;
  --data-coral-color-muted: #351008;
  --data-gray-color-emphasis: #576270;
  --data-gray-color-muted: #1c1c1c;
  --data-green-color-emphasis: #2f6f37;
  --data-green-color-muted: #122117;
  --data-lemon-color-emphasis: #977b0c;
  --data-lemon-color-muted: #291d00;
  --data-lime-color-emphasis: #5f892f;
  --data-lime-color-muted: #141f0f;
  --data-olive-color-emphasis: #7a8321;
  --data-olive-color-muted: #171e0b;
  --data-orange-color-emphasis: #984b10;
  --data-orange-color-muted: #311708;
  --data-pine-color-emphasis: #18915e;
  --data-pine-color-muted: #082119;
  --data-pink-color-emphasis: #d34591;
  --data-pink-color-muted: #2d1524;
  --data-plum-color-emphasis: #b643ef;
  --data-plum-color-muted: #2a0e3f;
  --data-purple-color-emphasis: #975bf1;
  --data-purple-color-muted: #211047;
  --data-red-color-emphasis: #eb3342;
  --data-red-color-muted: #3c0614;
  --data-teal-color-emphasis: #106c70;
  --data-teal-color-muted: #041f25;
  --data-yellow-color-emphasis: #895906;
  --data-yellow-color-muted: #2e1a00;
  --diffBlob-additionNum-bgColor: #28d7514d;
  --diffBlob-deletionNum-bgColor: #ff80804d;
  --diffBlob-hunkNum-bgColor-rest: #5cacff66;
  --display-auburn-bgColor-emphasis: #6d4340;
  --display-auburn-bgColor-muted: #271817;
  --display-auburn-borderColor-emphasis: #bf9592;
  --display-auburn-borderColor-muted: #3a2422;
  --display-auburn-fgColor: #d4b7b5;
  --display-blue-bgColor-emphasis: #0046a8;
  --display-blue-bgColor-muted: #001a47;
  --display-blue-borderColor-emphasis: #4da0ff;
  --display-blue-borderColor-muted: #002766;
  --display-blue-fgColor: #85c2ff;
  --display-brown-bgColor-emphasis: #5d4a32;
  --display-brown-bgColor-muted: #241c14;
  --display-brown-borderColor-emphasis: #b69a6d;
  --display-brown-borderColor-muted: #342a1d;
  --display-brown-fgColor: #cdbb98;
  --display-coral-bgColor-emphasis: #902a0e;
  --display-coral-bgColor-muted: #3c0614;
  --display-coral-borderColor-emphasis: #f7794b;
  --display-coral-borderColor-muted: #58091a;
  --display-coral-fgColor: #fdaa86;
  --display-cyan-bgColor-emphasis: #02536f;
  --display-cyan-bgColor-muted: #001f29;
  --display-cyan-borderColor-emphasis: #07ace4;
  --display-cyan-borderColor-muted: #002e3d;
  --display-cyan-fgColor: #45cbf7;
  --display-gray-bgColor-emphasis: #474e57;
  --display-gray-bgColor-muted: #1c1c1c;
  --display-gray-borderColor-emphasis: #92a1b5;
  --display-gray-borderColor-muted: #2a2b2d;
  --display-gray-fgColor: #b3c0d1;
  --display-green-bgColor-emphasis: #285830;
  --display-green-bgColor-muted: #122117;
  --display-green-borderColor-emphasis: #41b445;
  --display-green-borderColor-muted: #182f1f;
  --display-green-fgColor: #75d36f;
  --display-indigo-bgColor-emphasis: #3935c0;
  --display-indigo-bgColor-muted: #1b183f;
  --display-indigo-borderColor-emphasis: #9899ec;
  --display-indigo-borderColor-muted: #25215f;
  --display-indigo-fgColor: #b7baf6;
  --display-lemon-bgColor-emphasis: #614c05;
  --display-lemon-bgColor-muted: #291d00;
  --display-lemon-borderColor-emphasis: #ba9b12;
  --display-lemon-borderColor-muted: #372901;
  --display-lemon-fgColor: #d7bc1d;
  --display-lime-bgColor-emphasis: #375421;
  --display-lime-bgColor-muted: #141f0f;
  --display-lime-borderColor-emphasis: #7dae37;
  --display-lime-borderColor-muted: #1f3116;
  --display-lime-fgColor: #9fcc3e;
  --display-olive-bgColor-emphasis: #485219;
  --display-olive-bgColor-muted: #171e0b;
  --display-olive-borderColor-emphasis: #a2a626;
  --display-olive-borderColor-muted: #252d10;
  --display-olive-fgColor: #cbc025;
  --display-orange-bgColor-emphasis: #7b3c0e;
  --display-orange-bgColor-muted: #311708;
  --display-orange-borderColor-emphasis: #ed8326;
  --display-orange-borderColor-muted: #43200a;
  --display-orange-fgColor: #f6b06a;
  --display-pine-bgColor-emphasis: #115a3e;
  --display-pine-bgColor-muted: #082119;
  --display-pine-borderColor-emphasis: #1bb673;
  --display-pine-borderColor-muted: #0b3224;
  --display-pine-fgColor: #1bda81;
  --display-pink-bgColor-emphasis: #842a5d;
  --display-pink-bgColor-muted: #2d1524;
  --display-pink-borderColor-emphasis: #e57bb2;
  --display-pink-borderColor-muted: #451c35;
  --display-pink-fgColor: #f4a9cd;
  --display-plum-bgColor-emphasis: #7517ab;
  --display-plum-bgColor-muted: #2a0e3f;
  --display-plum-borderColor-emphasis: #d07ef7;
  --display-plum-borderColor-muted: #40125e;
  --display-plum-fgColor: #e4a5fd;
  --display-purple-bgColor-emphasis: #5b1cca;
  --display-purple-bgColor-muted: #211047;
  --display-purple-borderColor-emphasis: #b687f7;
  --display-purple-borderColor-muted: #31146b;
  --display-purple-fgColor: #d2affd;
  --display-red-bgColor-emphasis: #990f24;
  --display-red-bgColor-muted: #3c0614;
  --display-red-borderColor-emphasis: #f27d83;
  --display-red-borderColor-muted: #58091a;
  --display-red-fgColor: #f7adab;
  --display-teal-bgColor-emphasis: #0c555a;
  --display-teal-bgColor-muted: #041f25;
  --display-teal-borderColor-emphasis: #1cb0ab;
  --display-teal-borderColor-muted: #073036;
  --display-teal-fgColor: #24d6c4;
  --display-yellow-bgColor-emphasis: #6d4403;
  --display-yellow-bgColor-muted: #2e1a00;
  --display-yellow-borderColor-emphasis: #d3910d;
  --display-yellow-borderColor-muted: #3d2401;
  --display-yellow-fgColor: #edb431;
  --fgColor-attention: #f0b72f;
  --fgColor-danger: #ff9492;
  --fgColor-disabled: #656c76;
  --fgColor-link: var(--fgColor-accent);
  --fgColor-muted: #b7bdc8;
  --fgColor-neutral: #d1d7e0;
  --fgColor-open: var(--fgColor-success);
  --fgColor-severe: #fe9a2d;
  --fgColor-upsell: var(--fgColor-done);
  --header-bgColor: #151b23f2;
  --header-borderColor-divider: #656c76;
  --header-fgColor-logo: #f0f6fc;
  --headerSearch-bgColor: #0d1117;
  --headerSearch-borderColor: #2a313c;
  --highlight-neutral-bgColor: #f0b72f66;
  --label-auburn-bgColor-active: #543331;
  --label-auburn-bgColor-hover: #3a2422;
  --label-auburn-bgColor-rest: #271817;
  --label-auburn-fgColor-active: #d4b7b5;
  --label-auburn-fgColor-hover: #c6a19f;
  --label-auburn-fgColor-rest: #bf9592;
  --label-blue-bgColor-active: #00378a;
  --label-blue-bgColor-hover: #002766;
  --label-blue-bgColor-rest: #001a47;
  --label-blue-fgColor-active: #85c2ff;
  --label-blue-fgColor-hover: #61adff;
  --label-blue-fgColor-rest: #4da0ff;
  --label-brown-bgColor-active: #483a28;
  --label-brown-bgColor-hover: #342a1d;
  --label-brown-bgColor-rest: #241c14;
  --label-brown-fgColor-active: #cdbb98;
  --label-brown-fgColor-hover: #bfa77d;
  --label-brown-fgColor-rest: #b69a6d;
  --label-coral-bgColor-active: #72220d;
  --label-coral-bgColor-hover: #51180b;
  --label-coral-bgColor-rest: #351008;
  --label-coral-fgColor-active: #fdaa86;
  --label-coral-fgColor-hover: #fa8c61;
  --label-coral-fgColor-rest: #f7794b;
  --label-cyan-bgColor-active: #014156;
  --label-cyan-bgColor-hover: #002e3d;
  --label-cyan-bgColor-rest: #001f29;
  --label-cyan-fgColor-active: #45cbf7;
  --label-cyan-fgColor-hover: #09b7f1;
  --label-cyan-fgColor-rest: #07ace4;
  --label-gray-bgColor-active: #393d41;
  --label-gray-bgColor-hover: #2a2b2d;
  --label-gray-bgColor-rest: #1c1c1c;
  --label-gray-fgColor-active: #b3c0d1;
  --label-gray-fgColor-hover: #9babbf;
  --label-gray-fgColor-rest: #92a1b5;
  --label-green-bgColor-active: #214529;
  --label-green-bgColor-hover: #182f1f;
  --label-green-bgColor-rest: #122117;
  --label-green-fgColor-active: #75d36f;
  --label-green-fgColor-hover: #46c144;
  --label-green-fgColor-rest: #41b445;
  --label-indigo-bgColor-active: #312c90;
  --label-indigo-bgColor-hover: #25215f;
  --label-indigo-bgColor-rest: #1b183f;
  --label-indigo-fgColor-active: #b7baf6;
  --label-indigo-fgColor-hover: #a2a5f1;
  --label-indigo-fgColor-rest: #9899ec;
  --label-lemon-bgColor-active: #4f3c02;
  --label-lemon-bgColor-hover: #372901;
  --label-lemon-bgColor-rest: #291d00;
  --label-lemon-fgColor-active: #d7bc1d;
  --label-lemon-fgColor-hover: #c4a717;
  --label-lemon-fgColor-rest: #ba9b12;
  --label-lime-bgColor-active: #2c441d;
  --label-lime-bgColor-hover: #1f3116;
  --label-lime-bgColor-rest: #141f0f;
  --label-lime-fgColor-active: #9fcc3e;
  --label-lime-fgColor-hover: #89ba36;
  --label-lime-fgColor-rest: #7dae37;
  --label-olive-bgColor-active: #374115;
  --label-olive-bgColor-hover: #252d10;
  --label-olive-bgColor-rest: #171e0b;
  --label-olive-fgColor-active: #cbc025;
  --label-olive-fgColor-hover: #b2af24;
  --label-olive-fgColor-rest: #a2a626;
  --label-orange-bgColor-active: #632f0d;
  --label-orange-bgColor-hover: #43200a;
  --label-orange-bgColor-rest: #311708;
  --label-orange-fgColor-active: #f6b06a;
  --label-orange-fgColor-hover: #f1933b;
  --label-orange-fgColor-rest: #ed8326;
  --label-pine-bgColor-active: #0e4430;
  --label-pine-bgColor-hover: #0b3224;
  --label-pine-bgColor-rest: #082119;
  --label-pine-fgColor-active: #1bda81;
  --label-pine-fgColor-hover: #1ac176;
  --label-pine-fgColor-rest: #1bb673;
  --label-pink-bgColor-active: #65244a;
  --label-pink-bgColor-hover: #451c35;
  --label-pink-bgColor-rest: #2d1524;
  --label-pink-fgColor-active: #f4a9cd;
  --label-pink-fgColor-hover: #ec8dbd;
  --label-pink-fgColor-rest: #e57bb2;
  --label-plum-bgColor-active: #5c1688;
  --label-plum-bgColor-hover: #40125e;
  --label-plum-bgColor-rest: #2a0e3f;
  --label-plum-fgColor-active: #e4a5fd;
  --label-plum-fgColor-hover: #d889fa;
  --label-plum-fgColor-rest: #d07ef7;
  --label-purple-bgColor-active: #481a9e;
  --label-purple-bgColor-hover: #31146b;
  --label-purple-bgColor-rest: #211047;
  --label-purple-fgColor-active: #d2affd;
  --label-purple-fgColor-hover: #c398fb;
  --label-purple-fgColor-rest: #b687f7;
  --label-red-bgColor-active: #790c20;
  --label-red-bgColor-hover: #58091a;
  --label-red-bgColor-rest: #3c0614;
  --label-red-fgColor-active: #f7adab;
  --label-red-fgColor-hover: #f48b8d;
  --label-red-fgColor-rest: #f27d83;
  --label-teal-bgColor-active: #0a464d;
  --label-teal-bgColor-hover: #073036;
  --label-teal-bgColor-rest: #041f25;
  --label-teal-fgColor-active: #24d6c4;
  --label-teal-fgColor-hover: #1fbdb2;
  --label-teal-fgColor-rest: #1cb0ab;
  --label-yellow-bgColor-active: #5a3702;
  --label-yellow-bgColor-hover: #3d2401;
  --label-yellow-bgColor-rest: #2e1a00;
  --label-yellow-fgColor-active: #edb431;
  --label-yellow-fgColor-hover: #df9e11;
  --label-yellow-fgColor-rest: #d3910d;
  --menu-bgColor-active: #151b23;
  --overlay-backdrop-bgColor: #d1d7e066;
  --reactionButton-selected-bgColor-rest: #5cacff33;
  --reactionButton-selected-fgColor-hover: #91cbff;
  --selectMenu-bgColor-active: #1e60d5;
  --sideNav-bgColor-selected: #212830;
  --skeletonLoader-bgColor: #2a313c;
  --timelineBadge-bgColor: #212830;
  --underlineNav-borderColor-active: #ff967d;
  --avatar-bgColor: #ffffff1a;
  --avatar-borderColor: #ffffffe6;
  --bgColor-black: #010409;
  --bgColor-closed-emphasis: var(--bgColor-danger-emphasis);
  --bgColor-closed-muted: var(--bgColor-danger-muted);
  --bgColor-default: #010409;
  --bgColor-inset: #010409;
  --bgColor-inverse: #ffffff;
  --bgColor-open-emphasis: var(--bgColor-success-emphasis);
  --bgColor-open-muted: var(--bgColor-success-muted);
  --bgColor-upsell-emphasis: var(--bgColor-done-emphasis);
  --bgColor-upsell-muted: var(--bgColor-done-muted);
  --bgColor-white: #ffffff;
  --border-accent-emphasis: 0.0625rem solid #409eff;
  --border-accent-muted: 0.0625rem solid #5cacff;
  --border-attention-emphasis: 0.0625rem solid #e09b13;
  --border-attention-muted: 0.0625rem solid #edaa27;
  --border-danger-emphasis: 0.0625rem solid #ff6a69;
  --border-danger-muted: 0.0625rem solid #ff8080;
  --border-default: 0.0625rem solid #b7bdc8;
  --border-disabled: 0.0625rem solid #656c761a;
  --border-done-emphasis: 0.0625rem solid #b87fff;
  --border-done-muted: 0.0625rem solid #bf8fff;
  --border-severe-emphasis: 0.0625rem solid #e7811d;
  --border-severe-muted: 0.0625rem solid #f48b25;
  --border-sponsors-emphasis: 0.0625rem solid #ef6eb1;
  --border-sponsors-muted: 0.0625rem solid #f87cbd;
  --border-success-emphasis: 0.0625rem solid #09b43a;
  --border-success-muted: 0.0625rem solid #0ac740;
  --border-transparent: 0.0625rem solid #00000000;
  --borderColor-closed-emphasis: var(--borderColor-danger-emphasis);
  --borderColor-closed-muted: var(--borderColor-danger-muted);
  --borderColor-emphasis: var(--borderColor-default);
  --borderColor-muted: var(--borderColor-default);
  --borderColor-open-emphasis: var(--borderColor-success-emphasis);
  --borderColor-open-muted: var(--borderColor-success-muted);
  --borderColor-upsell-emphasis: var(--borderColor-done-emphasis);
  --borderColor-upsell-muted: var(--borderColor-done-muted);
  --button-danger-bgColor-active: var(--bgColor-danger-emphasis);
  --button-danger-bgColor-hover: var(--bgColor-danger-emphasis);
  --button-danger-bgColor-rest: var(--control-bgColor-rest);
  --button-danger-fgColor-active: #ffffff;
  --button-danger-fgColor-disabled: #ff949280;
  --button-danger-fgColor-hover: #ffffff;
  --button-danger-iconColor-hover: #ffffff;
  --button-default-bgColor-active: var(--control-bgColor-active);
  --button-default-bgColor-hover: var(--control-bgColor-hover);
  --button-default-bgColor-rest: var(--control-bgColor-rest);
  --button-default-bgColor-selected: var(--control-bgColor-active);
  --button-inactive-bgColor: var(--control-bgColor-rest);
  --button-invisible-bgColor-rest: var(--control-transparent-bgColor-rest);
  --button-invisible-borderColor-rest: var(--control-transparent-borderColor-rest);
  --button-outline-bgColor-hover: var(--control-bgColor-hover);
  --button-outline-fgColor-active: #ffffff;
  --button-primary-bgColor-rest: var(--bgColor-success-emphasis);
  --button-primary-fgColor-disabled: #ffffff66;
  --buttonCounter-danger-bgColor-disabled: #ad01160d;
  --buttonCounter-danger-bgColor-hover: #01040926;
  --buttonCounter-danger-bgColor-rest: #01040926;
  --buttonCounter-danger-fgColor-disabled: #ff949280;
  --buttonCounter-danger-fgColor-hover: #ffffff;
  --buttonCounter-danger-fgColor-rest: var(--fgColor-danger);
  --buttonCounter-invisible-bgColor-rest: var(--bgColor-neutral-muted);
  --buttonCounter-outline-bgColor-disabled: #194fb10d;
  --buttonCounter-primary-bgColor-rest: #01040926;
  --card-bgColor: var(--bgColor-muted);
  --codeMirror-activeline-bgColor: var(--bgColor-neutral-muted);
  --codeMirror-gutterMarker-fgColor-muted: var(--fgColor-muted);
  --codeMirror-lineNumber-fgColor: var(--fgColor-muted);
  --color-ansi-white-bright: #ffffff;
  --control-bgColor-disabled: var(--bgColor-disabled);
  --control-bgColor-selected: var(--control-bgColor-rest);
  --control-borderColor-danger: var(--borderColor-danger-emphasis);
  --control-borderColor-disabled: var(--borderColor-disabled);
  --control-borderColor-rest: var(--borderColor-default);
  --control-borderColor-success: var(--borderColor-success-emphasis);
  --control-borderColor-warning: var(--borderColor-attention-emphasis);
  --control-checked-bgColor-disabled: var(--fgColor-disabled);
  --control-checked-bgColor-rest: var(--bgColor-accent-emphasis);
  --control-checked-fgColor-disabled: #010409;
  --control-danger-bgColor-hover: var(--bgColor-danger-emphasis);
  --control-danger-fgColor-rest: var(--fgColor-danger);
  --control-fgColor-disabled: var(--fgColor-disabled);
  --control-iconColor-rest: var(--fgColor-muted);
  --control-transparent-bgColor-disabled: var(--bgColor-disabled);
  --control-transparent-borderColor-active: var(--borderColor-default);
  --control-transparent-borderColor-hover: var(--borderColor-default);
  --controlKnob-bgColor-checked: #ffffff;
  --controlTrack-bgColor-disabled: var(--fgColor-disabled);
  --controlTrack-borderColor-disabled: var(--fgColor-disabled);
  --counter-bgColor-emphasis: var(--bgColor-neutral-emphasis);
  --counter-bgColor-muted: var(--bgColor-neutral-muted);
  --counter-borderColor: var(--borderColor-default);
  --data-auburn-color-gradientStop-1: var(--data-auburn-color-muted);
  --data-auburn-color-gradientStop-2: #27181700;
  --data-blue-color-gradientStop-1: var(--data-blue-color-muted);
  --data-blue-color-gradientStop-2: #001a4700;
  --data-brown-color-gradientStop-1: var(--data-brown-color-muted);
  --data-brown-color-gradientStop-2: #241c1400;
  --data-coral-color-gradientStop-1: var(--data-coral-color-muted);
  --data-coral-color-gradientStop-2: #35100800;
  --data-gray-color-gradientStop-1: var(--data-gray-color-muted);
  --data-gray-color-gradientStop-2: #1c1c1c00;
  --data-green-color-gradientStop-1: var(--data-green-color-muted);
  --data-green-color-gradientStop-2: #12211700;
  --data-lemon-color-gradientStop-1: var(--data-lemon-color-muted);
  --data-lemon-color-gradientStop-2: #291d0000;
  --data-lime-color-gradientStop-1: var(--data-lime-color-muted);
  --data-lime-color-gradientStop-2: #141f0f00;
  --data-olive-color-gradientStop-1: var(--data-olive-color-muted);
  --data-olive-color-gradientStop-2: #171e0b00;
  --data-orange-color-gradientStop-1: var(--data-orange-color-muted);
  --data-orange-color-gradientStop-2: #31170800;
  --data-pine-color-gradientStop-1: var(--data-pine-color-muted);
  --data-pine-color-gradientStop-2: #08211900;
  --data-pink-color-gradientStop-1: var(--data-pink-color-muted);
  --data-pink-color-gradientStop-2: #2d152400;
  --data-plum-color-gradientStop-1: var(--data-plum-color-muted);
  --data-plum-color-gradientStop-2: #2a0e3f00;
  --data-purple-color-gradientStop-1: var(--data-purple-color-muted);
  --data-purple-color-gradientStop-2: #21104700;
  --data-red-color-gradientStop-1: var(--data-red-color-muted);
  --data-red-color-gradientStop-2: #3c061400;
  --data-teal-color-gradientStop-1: var(--data-teal-color-muted);
  --data-teal-color-gradientStop-2: #041f2500;
  --data-yellow-color-gradientStop-1: var(--data-yellow-color-muted);
  --data-yellow-color-gradientStop-2: #2e1a0000;
  --diffBlob-additionLine-bgColor: #0ac74033;
  --diffBlob-additionWord-bgColor: var(--bgColor-success-emphasis);
  --diffBlob-deletionLine-bgColor: #ff808033;
  --diffBlob-deletionWord-bgColor: var(--bgColor-danger-emphasis);
  --diffBlob-emptyLine-bgColor: var(--bgColor-muted);
  --diffBlob-emptyNum-bgColor: var(--bgColor-muted);
  --diffBlob-hunkLine-bgColor: #5cacff33;
  --diffBlob-hunkLine-fgColor: var(--fgColor-muted);
  --diffBlob-hunkNum-bgColor-hover: var(--bgColor-accent-emphasis);
  --fgColor-black: #010409;
  --fgColor-closed: var(--fgColor-danger);
  --fgColor-default: #ffffff;
  --fgColor-onEmphasis: #ffffff;
  --fgColor-onInverse: #010409;
  --fgColor-white: #ffffff;
  --focus-outlineColor: var(--borderColor-accent-emphasis);
  --header-fgColor-default: #ffffffb3;
  --overlay-bgColor: var(--bgColor-muted);
  --overlay-borderColor: var(--borderColor-default);
  --reactionButton-selected-fgColor-rest: var(--fgColor-link);
  --selectMenu-borderColor: var(--borderColor-default);
  --selection-bgColor: #194fb1b3;
  --shadow-floating-legacy: 0px 6px 12px -3px #01040966, 0px 6px 18px 0px #01040966;
  --shadow-inset: inset 0px 1px 0px 0px #0104093d;
  --shadow-resting-medium: 0px 1px 1px 0px #01040966, 0px 3px 6px 0px #010409cc;
  --shadow-resting-small: 0px 1px 1px 0px #01040999, 0px 1px 3px 0px #01040999;
  --shadow-resting-xsmall: 0px 1px 1px 0px #010409cc;
  --topicTag-borderColor: var(--borderColor-accent-emphasis);
  --treeViewItem-leadingVisual-iconColor-rest: var(--fgColor-muted);
  --underlineNav-iconColor-rest: var(--fgColor-muted);
  --border-closed-emphasis: var(--border-danger-emphasis);
  --border-closed-muted: var(--border-danger-muted);
  --border-emphasis: 0.0625rem solid #b7bdc8;
  --border-muted: 0.0625rem solid #b7bdc8;
  --border-open-emphasis: var(--border-success-emphasis);
  --border-open-muted: var(--border-success-muted);
  --border-upsell-emphasis: 0.0625rem solid #b87fff;
  --border-upsell-muted: 0.0625rem solid #bf8fff;
  --borderColor-neutral-emphasis: var(--borderColor-emphasis);
  --borderColor-neutral-muted: var(--borderColor-muted);
  --button-danger-bgColor-disabled: var(--control-bgColor-disabled);
  --button-danger-borderColor-rest: var(--control-borderColor-rest);
  --button-default-bgColor-disabled: var(--control-bgColor-disabled);
  --button-default-borderColor-disabled: var(--control-borderColor-disabled);
  --button-default-borderColor-rest: var(--control-borderColor-rest);
  --button-invisible-borderColor-hover: var(--control-transparent-borderColor-hover);
  --button-invisible-fgColor-disabled: var(--control-fgColor-disabled);
  --button-invisible-iconColor-disabled: var(--control-fgColor-disabled);
  --button-outline-bgColor-disabled: var(--control-bgColor-disabled);
  --button-primary-fgColor-rest: var(--fgColor-white);
  --button-primary-iconColor-rest: var(--fgColor-white);
  --codeMirror-bgColor: var(--bgColor-default);
  --codeMirror-cursor-fgColor: var(--fgColor-default);
  --codeMirror-fgColor: var(--fgColor-default);
  --codeMirror-gutterMarker-fgColor-default: var(--bgColor-default);
  --codeMirror-gutters-bgColor: var(--bgColor-default);
  --codeMirror-lines-bgColor: var(--bgColor-default);
  --codeMirror-matchingBracket-fgColor: var(--fgColor-default);
  --control-borderColor-emphasis: var(--borderColor-emphasis);
  --control-checked-borderColor-disabled: var(--control-checked-bgColor-disabled);
  --control-checked-fgColor-rest: var(--fgColor-onEmphasis);
  --control-fgColor-rest: var(--fgColor-default);
  --controlKnob-bgColor-disabled: var(--control-bgColor-disabled);
  --controlKnob-bgColor-rest: var(--bgColor-inset);
  --controlKnob-borderColor-checked: var(--control-checked-bgColor-rest);
  --controlKnob-borderColor-disabled: var(--control-bgColor-disabled);
  --controlTrack-borderColor-rest: var(--borderColor-emphasis);
  --controlTrack-fgColor-disabled: var(--fgColor-onEmphasis);
  --controlTrack-fgColor-rest: var(--fgColor-default);
  --data-auburn-gradient: linear-gradient(180deg, #271817 0%, #27181700 100%);
  --data-blue-gradient: linear-gradient(180deg, #001a47 0%, #001a4700 100%);
  --data-brown-gradient: linear-gradient(180deg, #241c14 0%, #241c1400 100%);
  --data-coral-gradient: linear-gradient(180deg, #351008 0%, #35100800 100%);
  --data-gray-gradient: linear-gradient(180deg, #1c1c1c 0%, #1c1c1c00 100%);
  --data-green-gradient: linear-gradient(180deg, #122117 0%, #12211700 100%);
  --data-lemon-gradient: linear-gradient(180deg, #291d00 0%, #291d0000 100%);
  --data-lime-gradient: linear-gradient(180deg, #141f0f 0%, #141f0f00 100%);
  --data-olive-gradient: linear-gradient(180deg, #171e0b 0%, #171e0b00 100%);
  --data-orange-gradient: linear-gradient(180deg, #311708 0%, #31170800 100%);
  --data-pine-gradient: linear-gradient(180deg, #082119 0%, #08211900 100%);
  --data-pink-gradient: linear-gradient(180deg, #2d1524 0%, #2d152400 100%);
  --data-plum-gradient: linear-gradient(180deg, #2a0e3f 0%, #2a0e3f00 100%);
  --data-purple-gradient: linear-gradient(180deg, #211047 0%, #21104700 100%);
  --data-red-gradient: linear-gradient(180deg, #3c0614 0%, #3c061400 100%);
  --data-teal-gradient: linear-gradient(180deg, #041f25 0%, #041f2500 100%);
  --data-yellow-gradient: linear-gradient(180deg, #2e1a00 0%, #2e1a0000 100%);
  --diffBlob-additionLine-fgColor: var(--fgColor-onEmphasis);
  --diffBlob-additionNum-fgColor: var(--fgColor-default);
  --diffBlob-additionWord-fgColor: var(--fgColor-default);
  --diffBlob-deletionLine-fgColor: var(--fgColor-onEmphasis);
  --diffBlob-deletionNum-fgColor: var(--fgColor-default);
  --diffBlob-deletionWord-fgColor: var(--fgColor-default);
  --diffBlob-expander-iconColor: var(--fgColor-default);
  --diffBlob-hunkNum-fgColor-hover: var(--fgColor-onEmphasis);
  --diffBlob-hunkNum-fgColor-rest: var(--fgColor-default);
  --focus-outline: 2px solid #409eff;
  --page-header-bgColor: var(--bgColor-default);
  --shadow-floating-large: 0px 0px 0px 1px #b7bdc8, 0px 24px 48px 0px #010409;
  --shadow-floating-medium: 0px 0px 0px 1px #b7bdc8, 0px 8px 16px -4px #01040966, 0px 4px 32px -4px #01040966, 0px 24px 48px -12px #01040966, 0px 48px 96px -24px #01040966;
  --shadow-floating-small: 0px 0px 0px 1px #b7bdc8, 0px 6px 12px -3px #01040966, 0px 6px 18px 0px #01040966;
  --shadow-floating-xlarge: 0px 0px 0px 1px #b7bdc8, 0px 32px 64px 0px #010409;
  --tooltip-bgColor: var(--bgColor-inverse);
  --tooltip-fgColor: var(--fgColor-onInverse);
  --border-neutral-emphasis: 0.0625rem solid #b7bdc8;
  --border-neutral-muted: 0.0625rem solid #b7bdc8;
  --button-default-borderColor-active: var(--button-default-borderColor-rest);
  --button-default-borderColor-hover: var(--button-default-borderColor-rest);
  --button-default-fgColor-rest: var(--control-fgColor-rest);
  --button-invisible-fgColor-active: var(--control-fgColor-rest);
  --button-invisible-fgColor-hover: var(--control-fgColor-rest);
  --button-invisible-fgColor-rest: var(--control-fgColor-rest);
  --controlKnob-borderColor-rest: var(--control-borderColor-emphasis);
  --underlineNav-borderColor-hover: var(--borderColor-neutral-muted);
  --button-outline-borderColor-hover: var(--button-default-borderColor-hover);
  --button-outline-borderColor-active: var(--button-outline-borderColor-hover);
}
@media (prefers-color-scheme: dark) {
  [data-color-mode="auto"][data-dark-theme="dark_high_contrast"],
  [data-color-mode="auto"][data-dark-theme="dark_high_contrast"] ::backdrop {
    --button-danger-iconColor-rest: #ffb1af;
    --button-primary-bgColor-active: #109135;
    --button-primary-bgColor-disabled: #048f2f;
    --button-primary-bgColor-hover: #08792b;
    --color-ansi-cyan: #39c5cf;
    --color-ansi-cyan-bright: #56d4dd;
    --control-checked-bgColor-active: #3c79d0;
    --control-checked-bgColor-hover: #2b64c1;
    --control-danger-bgColor-active: #c51120;
    --fgColor-accent: #74b9ff;
    --fgColor-done: #d3abff;
    --fgColor-sponsors: #ff90c8;
    --fgColor-success: #2bd853;
    --reactionButton-selected-bgColor-hover: #5dadff5c;
    --avatar-shadow: 0px 0px 0px 2px #0d1117;
    --avatarStack-fade-bgColor-default: #3d444d;
    --avatarStack-fade-bgColor-muted: #2a313c;
    --bgColor-accent-emphasis: #194fb1;
    --bgColor-accent-muted: #5cacff1a;
    --bgColor-attention-emphasis: #7b4900;
    --bgColor-attention-muted: #edaa2726;
    --bgColor-danger-emphasis: #ad0116;
    --bgColor-danger-muted: #ff80801a;
    --bgColor-disabled: #262c36;
    --bgColor-done-emphasis: #6921d7;
    --bgColor-done-muted: #bf8fff26;
    --bgColor-emphasis: #3d444d;
    --bgColor-muted: #151b23;
    --bgColor-neutral-emphasis: #3d444d;
    --bgColor-neutral-muted: #212830;
    --bgColor-severe-emphasis: #8f3c00;
    --bgColor-severe-muted: #f48b251a;
    --bgColor-sponsors-emphasis: #9c1d6a;
    --bgColor-sponsors-muted: #f87cbd1a;
    --bgColor-success-emphasis: #006222;
    --bgColor-success-muted: #0ac74026;
    --bgColor-transparent: #00000000;
    --borderColor-accent-emphasis: #409eff;
    --borderColor-accent-muted: #5cacff;
    --borderColor-attention-emphasis: #e09b13;
    --borderColor-attention-muted: #edaa27;
    --borderColor-danger-emphasis: #ff6a69;
    --borderColor-danger-muted: #ff8080;
    --borderColor-default: #b7bdc8;
    --borderColor-disabled: #656c761a;
    --borderColor-done-emphasis: #b87fff;
    --borderColor-done-muted: #bf8fff;
    --borderColor-severe-emphasis: #e7811d;
    --borderColor-severe-muted: #f48b25;
    --borderColor-sponsors-emphasis: #ef6eb1;
    --borderColor-sponsors-muted: #f87cbd;
    --borderColor-success-emphasis: #09b43a;
    --borderColor-success-muted: #0ac740;
    --borderColor-translucent: #2f3742;
    --borderColor-transparent: #00000000;
    --button-danger-borderColor-active: #ffb1af;
    --button-danger-borderColor-hover: #ffb1af;
    --button-danger-fgColor-rest: #ffb1af;
    --button-danger-shadow-selected: 0px 0px 0px 0px #000000;
    --button-default-shadow-resting: 0px 0px 0px 0px #000000;
    --button-inactive-fgColor: #b7bdc8;
    --button-invisible-bgColor-active: #212830;
    --button-invisible-bgColor-disabled: #00000000;
    --button-invisible-bgColor-hover: #151b23;
    --button-invisible-borderColor-disabled: #00000000;
    --button-invisible-iconColor-hover: #d1d7e0;
    --button-invisible-iconColor-rest: #d1d7e0;
    --button-outline-bgColor-active: #2672f3;
    --button-outline-bgColor-rest: #f0f6fc;
    --button-outline-fgColor-disabled: #74b9ff80;
    --button-outline-fgColor-hover: #71b7ff;
    --button-outline-fgColor-rest: #5cacff;
    --button-outline-shadow-selected: 0px 0px 0px 0px #000000;
    --button-primary-borderColor-active: #4ae168;
    --button-primary-borderColor-disabled: #4ae16866;
    --button-primary-borderColor-hover: #4ae168;
    --button-primary-borderColor-rest: #4ae168;
    --button-primary-shadow-selected: 0px 0px 0px 0px #000000;
    --button-star-iconColor: #f7c843;
    --buttonCounter-default-bgColor-rest: #2f3742;
    --buttonCounter-outline-bgColor-hover: #194fb133;
    --buttonCounter-outline-bgColor-rest: #194fb133;
    --buttonCounter-outline-fgColor-disabled: #74b9ff80;
    --buttonCounter-outline-fgColor-hover: #71b7ff;
    --buttonCounter-outline-fgColor-rest: #5cacff;
    --codeMirror-selection-bgColor: #5cacff66;
    --codeMirror-syntax-fgColor-comment: #656c76;
    --codeMirror-syntax-fgColor-constant: #91cbff;
    --codeMirror-syntax-fgColor-entity: #dbb7ff;
    --codeMirror-syntax-fgColor-keyword: #ff9492;
    --codeMirror-syntax-fgColor-storage: #ff9492;
    --codeMirror-syntax-fgColor-string: #addcff;
    --codeMirror-syntax-fgColor-support: #91cbff;
    --codeMirror-syntax-fgColor-variable: #ffb757;
    --color-ansi-black: #2f3742;
    --color-ansi-black-bright: #656c76;
    --color-ansi-blue: #71b7ff;
    --color-ansi-blue-bright: #91cbff;
    --color-ansi-gray: #656c76;
    --color-ansi-green: #28d751;
    --color-ansi-green-bright: #4ae168;
    --color-ansi-magenta: #cb9eff;
    --color-ansi-magenta-bright: #dbb7ff;
    --color-ansi-red: #ff9492;
    --color-ansi-red-bright: #ffb1af;
    --color-ansi-white: #f0f6fc;
    --color-ansi-yellow: #f0b72f;
    --color-ansi-yellow-bright: #f7c843;
    --color-prettylights-syntax-brackethighlighter-angle: #9198a1;
    --color-prettylights-syntax-brackethighlighter-unmatched: #ff8080;
    --color-prettylights-syntax-carriage-return-bg: #ff4445;
    --color-prettylights-syntax-carriage-return-text: #f0f6fc;
    --color-prettylights-syntax-comment: #9198a1;
    --color-prettylights-syntax-constant: #91cbff;
    --color-prettylights-syntax-constant-other-reference-link: #addcff;
    --color-prettylights-syntax-entity: #dbb7ff;
    --color-prettylights-syntax-entity-tag: #72f088;
    --color-prettylights-syntax-invalid-illegal-bg: #e82a2f;
    --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
    --color-prettylights-syntax-keyword: #ff9492;
    --color-prettylights-syntax-markup-bold: #f0f6fc;
    --color-prettylights-syntax-markup-changed-bg: #a74c00;
    --color-prettylights-syntax-markup-changed-text: #ffe1b4;
    --color-prettylights-syntax-markup-deleted-bg: #cc1421;
    --color-prettylights-syntax-markup-deleted-text: #ffdedb;
    --color-prettylights-syntax-markup-heading: #409eff;
    --color-prettylights-syntax-markup-ignored-bg: #318bf8;
    --color-prettylights-syntax-markup-ignored-text: #f0f6fc;
    --color-prettylights-syntax-markup-inserted-bg: #007728;
    --color-prettylights-syntax-markup-inserted-text: #acf7b6;
    --color-prettylights-syntax-markup-italic: #f0f6fc;
    --color-prettylights-syntax-markup-list: #fbd669;
    --color-prettylights-syntax-meta-diff-range: #dbb7ff;
    --color-prettylights-syntax-storage-modifier-import: #f0f6fc;
    --color-prettylights-syntax-string: #addcff;
    --color-prettylights-syntax-string-regexp: #72f088;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #3d444d;
    --color-prettylights-syntax-variable: #ffb757;
    --contribution-default-bgColor-0: #151b23;
    --contribution-default-bgColor-1: #007728;
    --contribution-default-bgColor-2: #02a232;
    --contribution-default-bgColor-3: #0ac740;
    --contribution-default-bgColor-4: #4ae168;
    --contribution-default-borderColor-0: #ffffff0d;
    --contribution-default-borderColor-1: #ffffff0d;
    --contribution-default-borderColor-2: #ffffff0d;
    --contribution-default-borderColor-3: #ffffff0d;
    --contribution-default-borderColor-4: #ffffff0d;
    --contribution-halloween-bgColor-1: #fac68f;
    --contribution-halloween-bgColor-2: #c46212;
    --contribution-halloween-bgColor-3: #984b10;
    --contribution-halloween-bgColor-4: #e3d04f;
    --contribution-winter-bgColor-1: #1e60d5;
    --contribution-winter-bgColor-2: #318bf8;
    --contribution-winter-bgColor-3: #71b7ff;
    --contribution-winter-bgColor-4: #caeaff;
    --control-bgColor-active: #2f3742;
    --control-bgColor-hover: #2a313c;
    --control-bgColor-rest: #262c36;
    --control-borderColor-selected: #f0f6fc;
    --control-checked-borderColor-active: #91cbff;
    --control-checked-borderColor-hover: #91cbff;
    --control-checked-borderColor-rest: #91cbff;
    --control-danger-fgColor-hover: #ff9492;
    --control-fgColor-placeholder: #b7bdc8;
    --control-transparent-bgColor-active: #3d444d;
    --control-transparent-bgColor-hover: #2f3742;
    --control-transparent-bgColor-rest: #00000000;
    --control-transparent-bgColor-selected: #2f3742;
    --control-transparent-borderColor-rest: #00000000;
    --controlTrack-bgColor-active: #3d444d;
    --controlTrack-bgColor-hover: #2f3742;
    --controlTrack-bgColor-rest: #2a313c;
    --data-auburn-color-emphasis: #a86f6b;
    --data-auburn-color-muted: #271817;
    --data-blue-color-emphasis: #0576ff;
    --data-blue-color-muted: #001a47;
    --data-brown-color-emphasis: #94774c;
    --data-brown-color-muted: #241c14;
    --data-coral-color-emphasis: #e1430e;
    --data-coral-color-muted: #351008;
    --data-gray-color-emphasis: #576270;
    --data-gray-color-muted: #1c1c1c;
    --data-green-color-emphasis: #2f6f37;
    --data-green-color-muted: #122117;
    --data-lemon-color-emphasis: #977b0c;
    --data-lemon-color-muted: #291d00;
    --data-lime-color-emphasis: #5f892f;
    --data-lime-color-muted: #141f0f;
    --data-olive-color-emphasis: #7a8321;
    --data-olive-color-muted: #171e0b;
    --data-orange-color-emphasis: #984b10;
    --data-orange-color-muted: #311708;
    --data-pine-color-emphasis: #18915e;
    --data-pine-color-muted: #082119;
    --data-pink-color-emphasis: #d34591;
    --data-pink-color-muted: #2d1524;
    --data-plum-color-emphasis: #b643ef;
    --data-plum-color-muted: #2a0e3f;
    --data-purple-color-emphasis: #975bf1;
    --data-purple-color-muted: #211047;
    --data-red-color-emphasis: #eb3342;
    --data-red-color-muted: #3c0614;
    --data-teal-color-emphasis: #106c70;
    --data-teal-color-muted: #041f25;
    --data-yellow-color-emphasis: #895906;
    --data-yellow-color-muted: #2e1a00;
    --diffBlob-additionNum-bgColor: #28d7514d;
    --diffBlob-deletionNum-bgColor: #ff80804d;
    --diffBlob-hunkNum-bgColor-rest: #5cacff66;
    --display-auburn-bgColor-emphasis: #6d4340;
    --display-auburn-bgColor-muted: #271817;
    --display-auburn-borderColor-emphasis: #bf9592;
    --display-auburn-borderColor-muted: #3a2422;
    --display-auburn-fgColor: #d4b7b5;
    --display-blue-bgColor-emphasis: #0046a8;
    --display-blue-bgColor-muted: #001a47;
    --display-blue-borderColor-emphasis: #4da0ff;
    --display-blue-borderColor-muted: #002766;
    --display-blue-fgColor: #85c2ff;
    --display-brown-bgColor-emphasis: #5d4a32;
    --display-brown-bgColor-muted: #241c14;
    --display-brown-borderColor-emphasis: #b69a6d;
    --display-brown-borderColor-muted: #342a1d;
    --display-brown-fgColor: #cdbb98;
    --display-coral-bgColor-emphasis: #902a0e;
    --display-coral-bgColor-muted: #3c0614;
    --display-coral-borderColor-emphasis: #f7794b;
    --display-coral-borderColor-muted: #58091a;
    --display-coral-fgColor: #fdaa86;
    --display-cyan-bgColor-emphasis: #02536f;
    --display-cyan-bgColor-muted: #001f29;
    --display-cyan-borderColor-emphasis: #07ace4;
    --display-cyan-borderColor-muted: #002e3d;
    --display-cyan-fgColor: #45cbf7;
    --display-gray-bgColor-emphasis: #474e57;
    --display-gray-bgColor-muted: #1c1c1c;
    --display-gray-borderColor-emphasis: #92a1b5;
    --display-gray-borderColor-muted: #2a2b2d;
    --display-gray-fgColor: #b3c0d1;
    --display-green-bgColor-emphasis: #285830;
    --display-green-bgColor-muted: #122117;
    --display-green-borderColor-emphasis: #41b445;
    --display-green-borderColor-muted: #182f1f;
    --display-green-fgColor: #75d36f;
    --display-indigo-bgColor-emphasis: #3935c0;
    --display-indigo-bgColor-muted: #1b183f;
    --display-indigo-borderColor-emphasis: #9899ec;
    --display-indigo-borderColor-muted: #25215f;
    --display-indigo-fgColor: #b7baf6;
    --display-lemon-bgColor-emphasis: #614c05;
    --display-lemon-bgColor-muted: #291d00;
    --display-lemon-borderColor-emphasis: #ba9b12;
    --display-lemon-borderColor-muted: #372901;
    --display-lemon-fgColor: #d7bc1d;
    --display-lime-bgColor-emphasis: #375421;
    --display-lime-bgColor-muted: #141f0f;
    --display-lime-borderColor-emphasis: #7dae37;
    --display-lime-borderColor-muted: #1f3116;
    --display-lime-fgColor: #9fcc3e;
    --display-olive-bgColor-emphasis: #485219;
    --display-olive-bgColor-muted: #171e0b;
    --display-olive-borderColor-emphasis: #a2a626;
    --display-olive-borderColor-muted: #252d10;
    --display-olive-fgColor: #cbc025;
    --display-orange-bgColor-emphasis: #7b3c0e;
    --display-orange-bgColor-muted: #311708;
    --display-orange-borderColor-emphasis: #ed8326;
    --display-orange-borderColor-muted: #43200a;
    --display-orange-fgColor: #f6b06a;
    --display-pine-bgColor-emphasis: #115a3e;
    --display-pine-bgColor-muted: #082119;
    --display-pine-borderColor-emphasis: #1bb673;
    --display-pine-borderColor-muted: #0b3224;
    --display-pine-fgColor: #1bda81;
    --display-pink-bgColor-emphasis: #842a5d;
    --display-pink-bgColor-muted: #2d1524;
    --display-pink-borderColor-emphasis: #e57bb2;
    --display-pink-borderColor-muted: #451c35;
    --display-pink-fgColor: #f4a9cd;
    --display-plum-bgColor-emphasis: #7517ab;
    --display-plum-bgColor-muted: #2a0e3f;
    --display-plum-borderColor-emphasis: #d07ef7;
    --display-plum-borderColor-muted: #40125e;
    --display-plum-fgColor: #e4a5fd;
    --display-purple-bgColor-emphasis: #5b1cca;
    --display-purple-bgColor-muted: #211047;
    --display-purple-borderColor-emphasis: #b687f7;
    --display-purple-borderColor-muted: #31146b;
    --display-purple-fgColor: #d2affd;
    --display-red-bgColor-emphasis: #990f24;
    --display-red-bgColor-muted: #3c0614;
    --display-red-borderColor-emphasis: #f27d83;
    --display-red-borderColor-muted: #58091a;
    --display-red-fgColor: #f7adab;
    --display-teal-bgColor-emphasis: #0c555a;
    --display-teal-bgColor-muted: #041f25;
    --display-teal-borderColor-emphasis: #1cb0ab;
    --display-teal-borderColor-muted: #073036;
    --display-teal-fgColor: #24d6c4;
    --display-yellow-bgColor-emphasis: #6d4403;
    --display-yellow-bgColor-muted: #2e1a00;
    --display-yellow-borderColor-emphasis: #d3910d;
    --display-yellow-borderColor-muted: #3d2401;
    --display-yellow-fgColor: #edb431;
    --fgColor-attention: #f0b72f;
    --fgColor-danger: #ff9492;
    --fgColor-disabled: #656c76;
    --fgColor-link: var(--fgColor-accent);
    --fgColor-muted: #b7bdc8;
    --fgColor-neutral: #d1d7e0;
    --fgColor-open: var(--fgColor-success);
    --fgColor-severe: #fe9a2d;
    --fgColor-upsell: var(--fgColor-done);
    --header-bgColor: #151b23f2;
    --header-borderColor-divider: #656c76;
    --header-fgColor-logo: #f0f6fc;
    --headerSearch-bgColor: #0d1117;
    --headerSearch-borderColor: #2a313c;
    --highlight-neutral-bgColor: #f0b72f66;
    --label-auburn-bgColor-active: #543331;
    --label-auburn-bgColor-hover: #3a2422;
    --label-auburn-bgColor-rest: #271817;
    --label-auburn-fgColor-active: #d4b7b5;
    --label-auburn-fgColor-hover: #c6a19f;
    --label-auburn-fgColor-rest: #bf9592;
    --label-blue-bgColor-active: #00378a;
    --label-blue-bgColor-hover: #002766;
    --label-blue-bgColor-rest: #001a47;
    --label-blue-fgColor-active: #85c2ff;
    --label-blue-fgColor-hover: #61adff;
    --label-blue-fgColor-rest: #4da0ff;
    --label-brown-bgColor-active: #483a28;
    --label-brown-bgColor-hover: #342a1d;
    --label-brown-bgColor-rest: #241c14;
    --label-brown-fgColor-active: #cdbb98;
    --label-brown-fgColor-hover: #bfa77d;
    --label-brown-fgColor-rest: #b69a6d;
    --label-coral-bgColor-active: #72220d;
    --label-coral-bgColor-hover: #51180b;
    --label-coral-bgColor-rest: #351008;
    --label-coral-fgColor-active: #fdaa86;
    --label-coral-fgColor-hover: #fa8c61;
    --label-coral-fgColor-rest: #f7794b;
    --label-cyan-bgColor-active: #014156;
    --label-cyan-bgColor-hover: #002e3d;
    --label-cyan-bgColor-rest: #001f29;
    --label-cyan-fgColor-active: #45cbf7;
    --label-cyan-fgColor-hover: #09b7f1;
    --label-cyan-fgColor-rest: #07ace4;
    --label-gray-bgColor-active: #393d41;
    --label-gray-bgColor-hover: #2a2b2d;
    --label-gray-bgColor-rest: #1c1c1c;
    --label-gray-fgColor-active: #b3c0d1;
    --label-gray-fgColor-hover: #9babbf;
    --label-gray-fgColor-rest: #92a1b5;
    --label-green-bgColor-active: #214529;
    --label-green-bgColor-hover: #182f1f;
    --label-green-bgColor-rest: #122117;
    --label-green-fgColor-active: #75d36f;
    --label-green-fgColor-hover: #46c144;
    --label-green-fgColor-rest: #41b445;
    --label-indigo-bgColor-active: #312c90;
    --label-indigo-bgColor-hover: #25215f;
    --label-indigo-bgColor-rest: #1b183f;
    --label-indigo-fgColor-active: #b7baf6;
    --label-indigo-fgColor-hover: #a2a5f1;
    --label-indigo-fgColor-rest: #9899ec;
    --label-lemon-bgColor-active: #4f3c02;
    --label-lemon-bgColor-hover: #372901;
    --label-lemon-bgColor-rest: #291d00;
    --label-lemon-fgColor-active: #d7bc1d;
    --label-lemon-fgColor-hover: #c4a717;
    --label-lemon-fgColor-rest: #ba9b12;
    --label-lime-bgColor-active: #2c441d;
    --label-lime-bgColor-hover: #1f3116;
    --label-lime-bgColor-rest: #141f0f;
    --label-lime-fgColor-active: #9fcc3e;
    --label-lime-fgColor-hover: #89ba36;
    --label-lime-fgColor-rest: #7dae37;
    --label-olive-bgColor-active: #374115;
    --label-olive-bgColor-hover: #252d10;
    --label-olive-bgColor-rest: #171e0b;
    --label-olive-fgColor-active: #cbc025;
    --label-olive-fgColor-hover: #b2af24;
    --label-olive-fgColor-rest: #a2a626;
    --label-orange-bgColor-active: #632f0d;
    --label-orange-bgColor-hover: #43200a;
    --label-orange-bgColor-rest: #311708;
    --label-orange-fgColor-active: #f6b06a;
    --label-orange-fgColor-hover: #f1933b;
    --label-orange-fgColor-rest: #ed8326;
    --label-pine-bgColor-active: #0e4430;
    --label-pine-bgColor-hover: #0b3224;
    --label-pine-bgColor-rest: #082119;
    --label-pine-fgColor-active: #1bda81;
    --label-pine-fgColor-hover: #1ac176;
    --label-pine-fgColor-rest: #1bb673;
    --label-pink-bgColor-active: #65244a;
    --label-pink-bgColor-hover: #451c35;
    --label-pink-bgColor-rest: #2d1524;
    --label-pink-fgColor-active: #f4a9cd;
    --label-pink-fgColor-hover: #ec8dbd;
    --label-pink-fgColor-rest: #e57bb2;
    --label-plum-bgColor-active: #5c1688;
    --label-plum-bgColor-hover: #40125e;
    --label-plum-bgColor-rest: #2a0e3f;
    --label-plum-fgColor-active: #e4a5fd;
    --label-plum-fgColor-hover: #d889fa;
    --label-plum-fgColor-rest: #d07ef7;
    --label-purple-bgColor-active: #481a9e;
    --label-purple-bgColor-hover: #31146b;
    --label-purple-bgColor-rest: #211047;
    --label-purple-fgColor-active: #d2affd;
    --label-purple-fgColor-hover: #c398fb;
    --label-purple-fgColor-rest: #b687f7;
    --label-red-bgColor-active: #790c20;
    --label-red-bgColor-hover: #58091a;
    --label-red-bgColor-rest: #3c0614;
    --label-red-fgColor-active: #f7adab;
    --label-red-fgColor-hover: #f48b8d;
    --label-red-fgColor-rest: #f27d83;
    --label-teal-bgColor-active: #0a464d;
    --label-teal-bgColor-hover: #073036;
    --label-teal-bgColor-rest: #041f25;
    --label-teal-fgColor-active: #24d6c4;
    --label-teal-fgColor-hover: #1fbdb2;
    --label-teal-fgColor-rest: #1cb0ab;
    --label-yellow-bgColor-active: #5a3702;
    --label-yellow-bgColor-hover: #3d2401;
    --label-yellow-bgColor-rest: #2e1a00;
    --label-yellow-fgColor-active: #edb431;
    --label-yellow-fgColor-hover: #df9e11;
    --label-yellow-fgColor-rest: #d3910d;
    --menu-bgColor-active: #151b23;
    --overlay-backdrop-bgColor: #d1d7e066;
    --reactionButton-selected-bgColor-rest: #5cacff33;
    --reactionButton-selected-fgColor-hover: #91cbff;
    --selectMenu-bgColor-active: #1e60d5;
    --sideNav-bgColor-selected: #212830;
    --skeletonLoader-bgColor: #2a313c;
    --timelineBadge-bgColor: #212830;
    --underlineNav-borderColor-active: #ff967d;
    --avatar-bgColor: #ffffff1a;
    --avatar-borderColor: #ffffffe6;
    --bgColor-black: #010409;
    --bgColor-closed-emphasis: var(--bgColor-danger-emphasis);
    --bgColor-closed-muted: var(--bgColor-danger-muted);
    --bgColor-default: #010409;
    --bgColor-inset: #010409;
    --bgColor-inverse: #ffffff;
    --bgColor-open-emphasis: var(--bgColor-success-emphasis);
    --bgColor-open-muted: var(--bgColor-success-muted);
    --bgColor-upsell-emphasis: var(--bgColor-done-emphasis);
    --bgColor-upsell-muted: var(--bgColor-done-muted);
    --bgColor-white: #ffffff;
    --border-accent-emphasis: 0.0625rem solid #409eff;
    --border-accent-muted: 0.0625rem solid #5cacff;
    --border-attention-emphasis: 0.0625rem solid #e09b13;
    --border-attention-muted: 0.0625rem solid #edaa27;
    --border-danger-emphasis: 0.0625rem solid #ff6a69;
    --border-danger-muted: 0.0625rem solid #ff8080;
    --border-default: 0.0625rem solid #b7bdc8;
    --border-disabled: 0.0625rem solid #656c761a;
    --border-done-emphasis: 0.0625rem solid #b87fff;
    --border-done-muted: 0.0625rem solid #bf8fff;
    --border-severe-emphasis: 0.0625rem solid #e7811d;
    --border-severe-muted: 0.0625rem solid #f48b25;
    --border-sponsors-emphasis: 0.0625rem solid #ef6eb1;
    --border-sponsors-muted: 0.0625rem solid #f87cbd;
    --border-success-emphasis: 0.0625rem solid #09b43a;
    --border-success-muted: 0.0625rem solid #0ac740;
    --border-transparent: 0.0625rem solid #00000000;
    --borderColor-closed-emphasis: var(--borderColor-danger-emphasis);
    --borderColor-closed-muted: var(--borderColor-danger-muted);
    --borderColor-emphasis: var(--borderColor-default);
    --borderColor-muted: var(--borderColor-default);
    --borderColor-open-emphasis: var(--borderColor-success-emphasis);
    --borderColor-open-muted: var(--borderColor-success-muted);
    --borderColor-upsell-emphasis: var(--borderColor-done-emphasis);
    --borderColor-upsell-muted: var(--borderColor-done-muted);
    --button-danger-bgColor-active: var(--bgColor-danger-emphasis);
    --button-danger-bgColor-hover: var(--bgColor-danger-emphasis);
    --button-danger-bgColor-rest: var(--control-bgColor-rest);
    --button-danger-fgColor-active: #ffffff;
    --button-danger-fgColor-disabled: #ff949280;
    --button-danger-fgColor-hover: #ffffff;
    --button-danger-iconColor-hover: #ffffff;
    --button-default-bgColor-active: var(--control-bgColor-active);
    --button-default-bgColor-hover: var(--control-bgColor-hover);
    --button-default-bgColor-rest: var(--control-bgColor-rest);
    --button-default-bgColor-selected: var(--control-bgColor-active);
    --button-inactive-bgColor: var(--control-bgColor-rest);
    --button-invisible-bgColor-rest: var(--control-transparent-bgColor-rest);
    --button-invisible-borderColor-rest: var(--control-transparent-borderColor-rest);
    --button-outline-bgColor-hover: var(--control-bgColor-hover);
    --button-outline-fgColor-active: #ffffff;
    --button-primary-bgColor-rest: var(--bgColor-success-emphasis);
    --button-primary-fgColor-disabled: #ffffff66;
    --buttonCounter-danger-bgColor-disabled: #ad01160d;
    --buttonCounter-danger-bgColor-hover: #01040926;
    --buttonCounter-danger-bgColor-rest: #01040926;
    --buttonCounter-danger-fgColor-disabled: #ff949280;
    --buttonCounter-danger-fgColor-hover: #ffffff;
    --buttonCounter-danger-fgColor-rest: var(--fgColor-danger);
    --buttonCounter-invisible-bgColor-rest: var(--bgColor-neutral-muted);
    --buttonCounter-outline-bgColor-disabled: #194fb10d;
    --buttonCounter-primary-bgColor-rest: #01040926;
    --card-bgColor: var(--bgColor-muted);
    --codeMirror-activeline-bgColor: var(--bgColor-neutral-muted);
    --codeMirror-gutterMarker-fgColor-muted: var(--fgColor-muted);
    --codeMirror-lineNumber-fgColor: var(--fgColor-muted);
    --color-ansi-white-bright: #ffffff;
    --control-bgColor-disabled: var(--bgColor-disabled);
    --control-bgColor-selected: var(--control-bgColor-rest);
    --control-borderColor-danger: var(--borderColor-danger-emphasis);
    --control-borderColor-disabled: var(--borderColor-disabled);
    --control-borderColor-rest: var(--borderColor-default);
    --control-borderColor-success: var(--borderColor-success-emphasis);
    --control-borderColor-warning: var(--borderColor-attention-emphasis);
    --control-checked-bgColor-disabled: var(--fgColor-disabled);
    --control-checked-bgColor-rest: var(--bgColor-accent-emphasis);
    --control-checked-fgColor-disabled: #010409;
    --control-danger-bgColor-hover: var(--bgColor-danger-emphasis);
    --control-danger-fgColor-rest: var(--fgColor-danger);
    --control-fgColor-disabled: var(--fgColor-disabled);
    --control-iconColor-rest: var(--fgColor-muted);
    --control-transparent-bgColor-disabled: var(--bgColor-disabled);
    --control-transparent-borderColor-active: var(--borderColor-default);
    --control-transparent-borderColor-hover: var(--borderColor-default);
    --controlKnob-bgColor-checked: #ffffff;
    --controlTrack-bgColor-disabled: var(--fgColor-disabled);
    --controlTrack-borderColor-disabled: var(--fgColor-disabled);
    --counter-bgColor-emphasis: var(--bgColor-neutral-emphasis);
    --counter-bgColor-muted: var(--bgColor-neutral-muted);
    --counter-borderColor: var(--borderColor-default);
    --data-auburn-color-gradientStop-1: var(--data-auburn-color-muted);
    --data-auburn-color-gradientStop-2: #27181700;
    --data-blue-color-gradientStop-1: var(--data-blue-color-muted);
    --data-blue-color-gradientStop-2: #001a4700;
    --data-brown-color-gradientStop-1: var(--data-brown-color-muted);
    --data-brown-color-gradientStop-2: #241c1400;
    --data-coral-color-gradientStop-1: var(--data-coral-color-muted);
    --data-coral-color-gradientStop-2: #35100800;
    --data-gray-color-gradientStop-1: var(--data-gray-color-muted);
    --data-gray-color-gradientStop-2: #1c1c1c00;
    --data-green-color-gradientStop-1: var(--data-green-color-muted);
    --data-green-color-gradientStop-2: #12211700;
    --data-lemon-color-gradientStop-1: var(--data-lemon-color-muted);
    --data-lemon-color-gradientStop-2: #291d0000;
    --data-lime-color-gradientStop-1: var(--data-lime-color-muted);
    --data-lime-color-gradientStop-2: #141f0f00;
    --data-olive-color-gradientStop-1: var(--data-olive-color-muted);
    --data-olive-color-gradientStop-2: #171e0b00;
    --data-orange-color-gradientStop-1: var(--data-orange-color-muted);
    --data-orange-color-gradientStop-2: #31170800;
    --data-pine-color-gradientStop-1: var(--data-pine-color-muted);
    --data-pine-color-gradientStop-2: #08211900;
    --data-pink-color-gradientStop-1: var(--data-pink-color-muted);
    --data-pink-color-gradientStop-2: #2d152400;
    --data-plum-color-gradientStop-1: var(--data-plum-color-muted);
    --data-plum-color-gradientStop-2: #2a0e3f00;
    --data-purple-color-gradientStop-1: var(--data-purple-color-muted);
    --data-purple-color-gradientStop-2: #21104700;
    --data-red-color-gradientStop-1: var(--data-red-color-muted);
    --data-red-color-gradientStop-2: #3c061400;
    --data-teal-color-gradientStop-1: var(--data-teal-color-muted);
    --data-teal-color-gradientStop-2: #041f2500;
    --data-yellow-color-gradientStop-1: var(--data-yellow-color-muted);
    --data-yellow-color-gradientStop-2: #2e1a0000;
    --diffBlob-additionLine-bgColor: #0ac74033;
    --diffBlob-additionWord-bgColor: var(--bgColor-success-emphasis);
    --diffBlob-deletionLine-bgColor: #ff808033;
    --diffBlob-deletionWord-bgColor: var(--bgColor-danger-emphasis);
    --diffBlob-emptyLine-bgColor: var(--bgColor-muted);
    --diffBlob-emptyNum-bgColor: var(--bgColor-muted);
    --diffBlob-hunkLine-bgColor: #5cacff33;
    --diffBlob-hunkLine-fgColor: var(--fgColor-muted);
    --diffBlob-hunkNum-bgColor-hover: var(--bgColor-accent-emphasis);
    --fgColor-black: #010409;
    --fgColor-closed: var(--fgColor-danger);
    --fgColor-default: #ffffff;
    --fgColor-onEmphasis: #ffffff;
    --fgColor-onInverse: #010409;
    --fgColor-white: #ffffff;
    --focus-outlineColor: var(--borderColor-accent-emphasis);
    --header-fgColor-default: #ffffffb3;
    --overlay-bgColor: var(--bgColor-muted);
    --overlay-borderColor: var(--borderColor-default);
    --reactionButton-selected-fgColor-rest: var(--fgColor-link);
    --selectMenu-borderColor: var(--borderColor-default);
    --selection-bgColor: #194fb1b3;
    --shadow-floating-legacy: 0px 6px 12px -3px #01040966, 0px 6px 18px 0px #01040966;
    --shadow-inset: inset 0px 1px 0px 0px #0104093d;
    --shadow-resting-medium: 0px 1px 1px 0px #01040966, 0px 3px 6px 0px #010409cc;
    --shadow-resting-small: 0px 1px 1px 0px #01040999, 0px 1px 3px 0px #01040999;
    --shadow-resting-xsmall: 0px 1px 1px 0px #010409cc;
    --topicTag-borderColor: var(--borderColor-accent-emphasis);
    --treeViewItem-leadingVisual-iconColor-rest: var(--fgColor-muted);
    --underlineNav-iconColor-rest: var(--fgColor-muted);
    --border-closed-emphasis: var(--border-danger-emphasis);
    --border-closed-muted: var(--border-danger-muted);
    --border-emphasis: 0.0625rem solid #b7bdc8;
    --border-muted: 0.0625rem solid #b7bdc8;
    --border-open-emphasis: var(--border-success-emphasis);
    --border-open-muted: var(--border-success-muted);
    --border-upsell-emphasis: 0.0625rem solid #b87fff;
    --border-upsell-muted: 0.0625rem solid #bf8fff;
    --borderColor-neutral-emphasis: var(--borderColor-emphasis);
    --borderColor-neutral-muted: var(--borderColor-muted);
    --button-danger-bgColor-disabled: var(--control-bgColor-disabled);
    --button-danger-borderColor-rest: var(--control-borderColor-rest);
    --button-default-bgColor-disabled: var(--control-bgColor-disabled);
    --button-default-borderColor-disabled: var(--control-borderColor-disabled);
    --button-default-borderColor-rest: var(--control-borderColor-rest);
    --button-invisible-borderColor-hover: var(--control-transparent-borderColor-hover);
    --button-invisible-fgColor-disabled: var(--control-fgColor-disabled);
    --button-invisible-iconColor-disabled: var(--control-fgColor-disabled);
    --button-outline-bgColor-disabled: var(--control-bgColor-disabled);
    --button-primary-fgColor-rest: var(--fgColor-white);
    --button-primary-iconColor-rest: var(--fgColor-white);
    --codeMirror-bgColor: var(--bgColor-default);
    --codeMirror-cursor-fgColor: var(--fgColor-default);
    --codeMirror-fgColor: var(--fgColor-default);
    --codeMirror-gutterMarker-fgColor-default: var(--bgColor-default);
    --codeMirror-gutters-bgColor: var(--bgColor-default);
    --codeMirror-lines-bgColor: var(--bgColor-default);
    --codeMirror-matchingBracket-fgColor: var(--fgColor-default);
    --control-borderColor-emphasis: var(--borderColor-emphasis);
    --control-checked-borderColor-disabled: var(--control-checked-bgColor-disabled);
    --control-checked-fgColor-rest: var(--fgColor-onEmphasis);
    --control-fgColor-rest: var(--fgColor-default);
    --controlKnob-bgColor-disabled: var(--control-bgColor-disabled);
    --controlKnob-bgColor-rest: var(--bgColor-inset);
    --controlKnob-borderColor-checked: var(--control-checked-bgColor-rest);
    --controlKnob-borderColor-disabled: var(--control-bgColor-disabled);
    --controlTrack-borderColor-rest: var(--borderColor-emphasis);
    --controlTrack-fgColor-disabled: var(--fgColor-onEmphasis);
    --controlTrack-fgColor-rest: var(--fgColor-default);
    --data-auburn-gradient: linear-gradient(180deg, #271817 0%, #27181700 100%);
    --data-blue-gradient: linear-gradient(180deg, #001a47 0%, #001a4700 100%);
    --data-brown-gradient: linear-gradient(180deg, #241c14 0%, #241c1400 100%);
    --data-coral-gradient: linear-gradient(180deg, #351008 0%, #35100800 100%);
    --data-gray-gradient: linear-gradient(180deg, #1c1c1c 0%, #1c1c1c00 100%);
    --data-green-gradient: linear-gradient(180deg, #122117 0%, #12211700 100%);
    --data-lemon-gradient: linear-gradient(180deg, #291d00 0%, #291d0000 100%);
    --data-lime-gradient: linear-gradient(180deg, #141f0f 0%, #141f0f00 100%);
    --data-olive-gradient: linear-gradient(180deg, #171e0b 0%, #171e0b00 100%);
    --data-orange-gradient: linear-gradient(180deg, #311708 0%, #31170800 100%);
    --data-pine-gradient: linear-gradient(180deg, #082119 0%, #08211900 100%);
    --data-pink-gradient: linear-gradient(180deg, #2d1524 0%, #2d152400 100%);
    --data-plum-gradient: linear-gradient(180deg, #2a0e3f 0%, #2a0e3f00 100%);
    --data-purple-gradient: linear-gradient(180deg, #211047 0%, #21104700 100%);
    --data-red-gradient: linear-gradient(180deg, #3c0614 0%, #3c061400 100%);
    --data-teal-gradient: linear-gradient(180deg, #041f25 0%, #041f2500 100%);
    --data-yellow-gradient: linear-gradient(180deg, #2e1a00 0%, #2e1a0000 100%);
    --diffBlob-additionLine-fgColor: var(--fgColor-onEmphasis);
    --diffBlob-additionNum-fgColor: var(--fgColor-default);
    --diffBlob-additionWord-fgColor: var(--fgColor-default);
    --diffBlob-deletionLine-fgColor: var(--fgColor-onEmphasis);
    --diffBlob-deletionNum-fgColor: var(--fgColor-default);
    --diffBlob-deletionWord-fgColor: var(--fgColor-default);
    --diffBlob-expander-iconColor: var(--fgColor-default);
    --diffBlob-hunkNum-fgColor-hover: var(--fgColor-onEmphasis);
    --diffBlob-hunkNum-fgColor-rest: var(--fgColor-default);
    --focus-outline: 2px solid #409eff;
    --page-header-bgColor: var(--bgColor-default);
    --shadow-floating-large: 0px 0px 0px 1px #b7bdc8, 0px 24px 48px 0px #010409;
    --shadow-floating-medium: 0px 0px 0px 1px #b7bdc8, 0px 8px 16px -4px #01040966, 0px 4px 32px -4px #01040966, 0px 24px 48px -12px #01040966, 0px 48px 96px -24px #01040966;
    --shadow-floating-small: 0px 0px 0px 1px #b7bdc8, 0px 6px 12px -3px #01040966, 0px 6px 18px 0px #01040966;
    --shadow-floating-xlarge: 0px 0px 0px 1px #b7bdc8, 0px 32px 64px 0px #010409;
    --tooltip-bgColor: var(--bgColor-inverse);
    --tooltip-fgColor: var(--fgColor-onInverse);
    --border-neutral-emphasis: 0.0625rem solid #b7bdc8;
    --border-neutral-muted: 0.0625rem solid #b7bdc8;
    --button-default-borderColor-active: var(--button-default-borderColor-rest);
    --button-default-borderColor-hover: var(--button-default-borderColor-rest);
    --button-default-fgColor-rest: var(--control-fgColor-rest);
    --button-invisible-fgColor-active: var(--control-fgColor-rest);
    --button-invisible-fgColor-hover: var(--control-fgColor-rest);
    --button-invisible-fgColor-rest: var(--control-fgColor-rest);
    --controlKnob-borderColor-rest: var(--control-borderColor-emphasis);
    --underlineNav-borderColor-hover: var(--borderColor-neutral-muted);
    --button-outline-borderColor-hover: var(--button-default-borderColor-hover);
    --button-outline-borderColor-active: var(--button-outline-borderColor-hover);
  }
}
